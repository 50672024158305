export interface AmplitudeEvent {
  eventName: string;
  params?: Record<string, string | number | boolean>;
}

export enum EVENTS {
  PAGE_VIEWED = 'Page Viewed',
  BUTTON_CLICKED = 'Button Clicked',

  // Scan events
  ROBOT_LOAD_FAILURE = '[ROBOT]_LOAD_FAILURE',
  ROBOT_LOAD_RETRY = '[ROBOT]_LOAD_RETRY',
  ROBOT_LOAD_SUCCESS = '[ROBOT]_LOAD_SUCCESS',
  SCAN_ANOTHER = '[ROBOT]_SCAN_ANOTHER',
  ROBOT_UNAVAILABLE = '[ROBOT]_ROBOT_UNAVAILABLE',
  AUTH_LOGOUT = '[AUTH]_LOGOUT',
  AUTH_AUTO_LOGOUT = '[AUTH]_AUTO_LOGOUT',
  SCANNER_OPEN = '[SCANNER]_OPEN',
  SCANNER_SCAN_ERROR = '[SCANNER]_SCAN_ERROR',
  NETWORK_API_ERROR = '[NETWORK]_API_ERROR',
}
