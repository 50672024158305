import '@coco/ui-web/src/css/coco-styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'src/css/ToastOverrides.css';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import * as FullStory from '@fullstory/browser';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import { ComponentType, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';

import { AccessProvider, AppContext, Header } from '../components';
import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_ORIGIN_URL,
  FULLSTORY_ORG_ID,
  LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
  NODE_ENV,
} from '../lib/env';

// const ENABLE_DD = ['staging', 'beta', 'prod'].includes(NEXT_PUBLIC_AWS_ENV);

// datadogRum.init({
//   applicationId: '3ec3e2c7-ba1d-4933-b69c-3bd330c25822',
//   clientToken: 'pub1245c56d16cd8a7d1cac61b223bdf6de',
//   site: 'datadoghq.com',
//   service: 'merchant',
//   env: NEXT_PUBLIC_AWS_ENV,
//   version: NEXT_PUBLIC_APP_VERSION,
//   sessionSampleRate: ENABLE_DD ? 100 : 0,
//   sessionReplaySampleRate: ENABLE_DD ? 20 : 0,
//   trackUserInteractions: true,
//   trackResources: true,
//   trackLongTasks: true,
//   defaultPrivacyLevel: 'mask-user-input',
//   allowedTracingUrls: [
//     /https:\/\/(deliveries|operations|device|state|routing|storefront|integrations).*\.cocodelivery\.com/,
//   ],
//   traceSampleRate: ENABLE_DD ? 100 : 0,
// });

/*
 * https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#3-protecting-a-route-in-a-nextjs-app-in-spa-mode
 * */
const handleRedirectCallback = (appState: AppState | undefined): void => {
  Router.replace(appState?.returnTo || '/');
};

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    if (window) {
      FullStory.init({ orgId: FULLSTORY_ORG_ID, devMode: NODE_ENV === 'development' });
    }
  }, []);

  return (
    <>
      <Head>
        <title>Coco | Merchant Fleet</title> {/* https://nextjs.org/docs/messages/no-document-title */}
      </Head>

      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        redirectUri={AUTH0_ORIGIN_URL}
        cacheLocation="localstorage"
        onRedirectCallback={handleRedirectCallback}
        useRefreshTokens
      >
        <QueryClientProvider client={new QueryClient()}>
          <AccessProvider>
            <AppContext.Provider>
              <Header />
              <ToastContainer
                position="top-center"
                draggable={false}
                closeButton
                pauseOnHover={false}
                pauseOnFocusLoss={false}
                autoClose={2000}
                progressStyle={{
                  height: '10px',
                  background: 'var(--toastify-color-progress-dark)',
                }}
                style={{ position: 'fixed' }}
                toastStyle={{ height: 'auto', margin: 'auto', maxWidth: 400, padding: '1rem' }}
                limit={1}
              />
              <Component {...pageProps} />
            </AppContext.Provider>
          </AccessProvider>
        </QueryClientProvider>
      </Auth0Provider>
    </>
  );
}

export default withLDProvider({
  clientSideID: LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
})(MyApp as ComponentType<object>); // https://launchdarkly.com/blog/using-launchdarkly-with-typescript/
