import { create } from 'zustand';

import { MerchantFleetAccessConfig } from '../@types';

export interface RobotStateValues {
  merchantAccessConfig: MerchantFleetAccessConfig | null;
  robotId: string | null;
}

export interface RobotState extends RobotStateValues {
  setMerchantAccessConfig: (merchantAccessConfig: MerchantFleetAccessConfig | null) => void;
  setRobotId: (robotId: string | null) => void;
}

const initialState: RobotStateValues = {
  merchantAccessConfig: null,
  robotId: null,
};

export const useRobotStore = create<RobotState>((set) => ({
  ...initialState,
  setMerchantAccessConfig: (merchantAccessConfig) => set({ merchantAccessConfig }),
  setRobotId: (robotId) => set({ robotId }),
}));
