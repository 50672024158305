export enum LID_ACTIONS {
  OPEN = 'open',
  CLOSE = 'close',
}

export enum LID_STATE {
  OPENING = 'opening',
  CLOSING = 'closing',
}

export interface LidResponse {
  message: string;
  robotId: string;
  status: number;
}

export interface ToggleLidParams {
  serialNo: string;
  action: LID_ACTIONS;
}
