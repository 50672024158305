import styled from '@emotion/styled';

const Button = styled.button(
  {
    background: '#141313',
    width: '100%',
    maxWidth: '500px',
    height: '64px',
    fontSize: '18px',
    color: 'white',
    borderRadius: '4px',
    border: 0,
    fontWeight: 500,
    cursor: 'pointer',
    '&:active': {
      opacity: '50%',
    },
  },
  ({ style }) => ({ ...style })
);

export default Button;
