// NOTE: Required due to weird conflicting prettier and eslint auto-apply rules on Alex's machine
/* eslint-disable prettier/prettier */
import styled from '@emotion/styled';

import { Typography } from '@coco/ui-web';

import {
  GreenBattery as GreenBatteryIcon,
  OrangeBattery as OrangeBatteryIcon,
  RedBattery as RedBatteryIcon,
  YellowBattery as YellowBatteryIcon,
} from '../assets';

const BatteryContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: -3px;
`;

const BatteryPercentage = styled.span`
  margin-left: 5px;
  margin-top: 3px;
`;

interface BatteryProps {
  battery: number;
  serial: string;
}

export const Battery = ({ battery, serial }: BatteryProps) => {
  const getBatteryIcon = (value: number) =>
    value > 70
      ? GreenBatteryIcon.src
      : value > 40
        ? YellowBatteryIcon.src
        : value > 20
          ? OrangeBatteryIcon.src
          : RedBatteryIcon.src;

  return (
    <BatteryContainer>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img alt={serial + '-battery'} src={getBatteryIcon(battery)} height={30} width={35} />
      <BatteryPercentage className={Typography.BODY_MD}>{battery}%</BatteryPercentage>
    </BatteryContainer>
  );
};
