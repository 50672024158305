import { CSSProperties } from 'react';

import { Colors, Typography } from '@coco/ui-web';

import { Button } from '../components';

export const MainButton = ({
  onClick,
  style,
  title,
}: {
  onClick?: () => void;
  style?: CSSProperties;
  title: string;
}) => {
  return (
    <Button
      onClick={onClick}
      style={{
        backgroundColor: Colors.bluegrey500,
        borderRadius: 8,
        color: Colors.black,
        height: 52,
        marginBottom: 20,
        maxWidth: 300,
        ...style,
      }}
    >
      <span className={Typography.DISPLAY_XS_MED}>{title}</span>
    </Button>
  );
};
