import styled from '@emotion/styled';
import { CSSProperties, ReactNode } from 'react';

import { Colors, Typography } from '@coco/ui-web';

interface Props {
  iconUrl?: string;
  children: ReactNode;
  onClick?: () => void;
  style?: CSSProperties;
}

const PillContainer = styled.div(
  {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '5px 10px',
    border: `1px solid ${Colors.black}`,
    borderRadius: 30,
    backgroundColor: 'white',
    height: 42,
    justifyContent: 'center',
  },
  ({ style }) => ({ ...style })
);

const Icon = styled.img`
  height: 20px;
  margin-right: 5px;
`;

const CopyContainer = styled.div`
  padding-top: 2px;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 680px) {
    max-width: 80px;
  }
  @media (max-width: 600px) {
    max-width: 140px;
  }
`;

const Pill = ({ iconUrl, children, onClick, style }: Props) => {
  return (
    <PillContainer onClick={onClick} style={style}>
      {iconUrl && <Icon src={iconUrl} />}
      <CopyContainer className={Typography.LABEL_XL}>{children}</CopyContainer>
    </PillContainer>
  );
};

export default Pill;
