import styled from '@emotion/styled';
import { CSSProperties } from 'react';

import { Colors } from '@coco/ui-web';

const CardContent = styled.div(
  {
    backgroundColor: Colors.white,
    borderRadius: 10,
    boxShadow: '-2px 3px 13px 0px #0000001A',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    height: 40,
    justifyContent: 'space-between',
    marginBottom: 5,
    marginTop: 5,
    padding: 20,
  },
  ({ style }) => ({ ...style })
);

const CardContainer = styled.div(
  {
    maxWidth: '1000px',
    width: '100%',
  },
  ({ style }) => ({ ...style })
);

export const Card = ({ children, style }: { children: React.ReactNode; style?: CSSProperties }) => (
  <CardContainer>
    <CardContent style={style}>{children}</CardContent>
  </CardContainer>
);
