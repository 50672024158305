import styled from '@emotion/styled';

export const Container = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ({ style }) => ({ ...style })
);
