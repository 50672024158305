import { UseMutationOptions, UseQueryOptions } from 'react-query';

export type PromiseValue<PromiseType> = PromiseType extends PromiseLike<infer Value>
  ? PromiseValue<Value>
  : PromiseType;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QueryConfig<FetcherFnType extends (...args: any) => unknown> = UseQueryOptions<
  PromiseValue<ReturnType<FetcherFnType>>
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MutationConfig<FetcherFnType extends (...args: any) => unknown> = UseMutationOptions<
  PromiseValue<ReturnType<FetcherFnType>>
>;

export enum QUERY_KEYS {
  ROBOTS = 'robots',
}

export const robotQueryKeys = {
  all: [QUERY_KEYS.ROBOTS] as const,
  load: (serialNo: string) => [QUERY_KEYS.ROBOTS, 'load', serialNo] as const,
  merchant: (locationId: string) => [QUERY_KEYS.ROBOTS, 'merchant', locationId] as const,
  toggle: (serialNo: string) => [QUERY_KEYS.ROBOTS, 'toggle', serialNo] as const,
  checkIn: (serialNo: string) => [QUERY_KEYS.ROBOTS, 'checkIn', serialNo] as const,
  checkOut: (serialNo: string) => [QUERY_KEYS.ROBOTS, 'checkOut', serialNo] as const,
  deploy: (serialNo: string, undeploy: boolean) =>
    [QUERY_KEYS.ROBOTS, undeploy ? 'undeploy' : 'deploy', serialNo] as const,
};
