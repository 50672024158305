import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { createContext, ReactNode, useCallback, useContext } from 'react';

import { DEVICE_API_ENDPOINT, OPERATIONS_API_ENDPOINT } from '../lib/env';

const API_TIMEOUT_MS = 5000; // 5 seconds

const deviceApi = axios.create({
  baseURL: DEVICE_API_ENDPOINT,
  timeout: API_TIMEOUT_MS,
});

const operationsApi = axios.create({
  baseURL: OPERATIONS_API_ENDPOINT,
  timeout: API_TIMEOUT_MS,
});

const AppContext = createContext({
  deviceApi: {} as AxiosInstance,
  operationsApi: {} as AxiosInstance,
});

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  /*
    auth0 devs don't want to expose singleton instance of auth client, therefore we must declare interceptors
    in react code to get access to getAccessTokenSilently and getIdTokenClaims methods
    source: https://gist.github.com/adamjmcgrath/0ed6a04047aad16506ca24d85f1b2a5c
  */
  const interceptorFn = useCallback(
    async (config: AxiosRequestConfig) => {
      await getAccessTokenSilently();

      const claims = await getIdTokenClaims();

      if (config.headers) config.headers.authorization = `Bearer ${claims?.__raw}`;
      return config;
    },
    [getAccessTokenSilently, getIdTokenClaims]
  );

  deviceApi.interceptors.request.use(interceptorFn);
  operationsApi.interceptors.request.use(interceptorFn);

  const context = { deviceApi, operationsApi };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

const useAppContext = () => {
  return useContext(AppContext);
};

const AppContextObject = {
  Provider,
  useAppContext,
};

export default AppContextObject;
