export interface ParkingLotCurrentlyAssignedTo {
  id: string;
  name: string;
}

export interface Robot {
  serial: string;
  createdAt?: Date;
  parkingLotCurrentlyAssignedTo?: ParkingLotCurrentlyAssignedTo;
}

export enum RobotAvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  BUSY = 'BUSY',
}

export enum RobotState {
  ON_TRIP = 'ON_TRIP',
  GROUNDED = 'GROUNDED',
  PARKED = 'PARKED',
  OFF_DUTY = 'OFF_DUTY',
}

export const RobotStateSortOrder = {
  [RobotState.GROUNDED]: 0,
  [RobotState.OFF_DUTY]: 1,
  [RobotState.PARKED]: 2,
  [RobotState.ON_TRIP]: 3,
};

export interface IdAndName {
  id: string;
  name: string;
}

export interface ParkingLotRobot {
  availabilityStatus: RobotAvailabilityStatus;
  battery: { currentBatteryPercentage: number };
  currentParkingLot: IdAndName;
  currentStorageLocation: IdAndName;
  robotState: { robotState: RobotState };
  serial: string;
}
