import { Typography } from '@coco/ui-web';

import { Coco1Img } from '../assets';
import { Container } from './shared';

export const NoRobots = () => {
  return (
    <Container style={{ marginBottom: 100 }}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src={Coco1Img.src} alt="robot-image" height={400} width={400} />
      <span className={Typography.DISPLAY_XS_MED}>No Robots Deployed</span>
    </Container>
  );
};
