import styled from '@emotion/styled';
import { CSSProperties } from 'react';
import { BarLoader } from 'react-spinners';

const Container = styled.div(
  {
    marginTop: 50,
    paddingHorizontal: 30,
  },
  ({ style }) => ({ ...style })
);

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Loader = ({ style }: { style?: CSSProperties }) => {
  return (
    <Container style={style}>
      <LoaderContainer>
        <BarLoader color="#141313" />
      </LoaderContainer>
    </Container>
  );
};

export default Loader;
