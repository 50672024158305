import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { ReactNode, useEffect, useState } from 'react';

import { useRobotStore } from 'src/stores';
import { MerchantFleetAccessRecord, UserRole } from '../@types';
import FullPageLoader from './FullPageLoader';
import Unauthorized from './Unauthorized';

const ROLE_GROUP_KEY = 'https://cocodelivery.com/schemas/identity/claims/roles';

interface Props {
  children: ReactNode;
}

const AccessProvider = ({ children }: Props) => {
  const { isLoading, isAuthenticated, user } = useAuth0();
  const ldClient = useLDClient();
  const { setMerchantAccessConfig } = useRobotStore();

  const merchantFleetAccess = ldClient?.variation('merchant-fleet-access', {}) as MerchantFleetAccessRecord;

  const [appReady, setAppReady] = useState<boolean>(false);
  const [showUnauthorized, setShowUnauthorized] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated && user?.sub && merchantFleetAccess) {
      const roles = user[ROLE_GROUP_KEY];
      if (!roles.includes(UserRole.Merchants) || !(user.sub in merchantFleetAccess)) {
        setShowUnauthorized(true);
        setMerchantAccessConfig(null);
      } else {
        setMerchantAccessConfig(merchantFleetAccess[user.sub]);
      }

      setAppReady(true);
    }
  }, [isAuthenticated, merchantFleetAccess, setMerchantAccessConfig, showUnauthorized, user]);

  if (isLoading || !appReady) {
    return <FullPageLoader />;
  }

  if (showUnauthorized) {
    return <Unauthorized />;
  }

  return <>{children}</>;
};

export default withAuthenticationRequired(AccessProvider);
