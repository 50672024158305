import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import { Divider, Menu } from '@mui/material';
import Link from 'next/link';
import { MouseEvent, useCallback, useState } from 'react';

import { CDN_URL, Colors } from '@coco/ui-web';

import { EVENTS } from 'src/@types';
import { trackSegment } from 'src/stores/segment';
import { AUTH0_ORIGIN_URL } from '../lib/env';

const MAX_ACCOUNT_NAME_LENGTH = 15;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 25px;
  background-color: ${Colors.white};
  height: 82px;
`;

const CocoLogo = styled.img`
  height: 32px;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  width: 230px;
`;

const HelpContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${Colors.grey100};
  }
  transition: 0.5s;
`;
const PhoneIcon = styled.img`
  height: 24px;
`;

const NeedHelp = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: ${Colors.grey900};
  margin-left: 10px;
  padding-top: 3px;
`;

const Avatar = styled.img`
  height: 42px;
  width: 42px;
  margin-left: auto;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
`;

const MenuItem = styled.div`
  padding: 10px 10px 20px 10px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  cursor: default;
`;

const MenuSelectable = styled(MenuItem)`
  padding: 10px;
  cursor: pointer;
  font-weight: 500;
  &:hover {
    background-color: ${Colors.grey100};
  }
  transition: 0.5s;
`;

const PhoneNumber = styled.div`
  padding: 10px;
  font-weight: 500;
  font-size: 15px;
`;

const Header = () => {
  const { logout, user } = useAuth0();
  const [anchorElHelp, setAnchorElHelp] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  }, []);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenHelpMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      trackSegment(EVENTS.BUTTON_CLICKED, {
        button_name: 'Need Help',
        auth_id: user?.sub,
        button_clicked_at: Date.now(),
      });

      setAnchorElHelp(event.currentTarget);
    },
    [user?.sub]
  );

  const handleCloseHelpMenu = () => {
    setAnchorElHelp(null);
  };

  const handleLogout = useCallback(() => {
    trackSegment(EVENTS.BUTTON_CLICKED, {
      button_name: 'Log Out',
      auth_id: user?.sub,
      button_clicked_at: Date.now(),
    });

    logout({ returnTo: AUTH0_ORIGIN_URL });
  }, [logout, user?.sub]);

  let trimmedAccountName = user?.name ?? '';

  if (trimmedAccountName.length > MAX_ACCOUNT_NAME_LENGTH) {
    trimmedAccountName = `${trimmedAccountName.slice(0, MAX_ACCOUNT_NAME_LENGTH)}...`;
  }

  return (
    <>
      <Container>
        <Link href={`/`}>
          <CocoLogo src={`${CDN_URL}/images/Coco-gradient.png`} />
        </Link>
        <RightSection>
          <HelpContainer onClick={handleOpenHelpMenu}>
            <PhoneIcon src={`${CDN_URL}/icons/phone-black.png`} />
            <NeedHelp>Need Help?</NeedHelp>
          </HelpContainer>

          <Avatar onClick={handleOpenUserMenu} src={user?.picture ?? `${CDN_URL}/images/avatar.png`} />
        </RightSection>
      </Container>

      <Menu
        sx={{ marginTop: '50px' }}
        anchorEl={anchorElHelp}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElHelp)}
        onClose={handleCloseHelpMenu}
      >
        <MenuItem>Coco Merchant Support</MenuItem>
        <Divider sx={{ marginBottom: '10px' }} />
        <PhoneNumber>Please call +1 (217) 616-2626</PhoneNumber>
      </Menu>

      <Menu
        sx={{ marginTop: '50px' }}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem>{trimmedAccountName}</MenuItem>
        <Divider sx={{ marginBottom: '10px' }} />
        <MenuSelectable onClick={handleLogout}>Log Out</MenuSelectable>
      </Menu>
    </>
  );
};

export default Header;
